"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/logger.ts
var logger_exports = {};
__export(logger_exports, {
  Logger: () => Logger,
  logger: () => logger,
  timerEnd: () => timerEnd,
  timerStart: () => timerStart
});
module.exports = __toCommonJS(logger_exports);
var IS_BROWSER = typeof window !== "undefined";
var LOG_LEVELS = ["debug", "info", "warn", "error"];
var LOG_LEVEL = LOG_LEVELS.find((l) => l === process.env.LOG_LEVEL) ?? // Default to 'debug' in dev, 'info' in prod
process.env.NODE_ENV === "development" ? "debug" : "info";
function timerStart() {
  if (IS_BROWSER) {
    return;
  }
  return process.hrtime();
}
function timerEnd(since) {
  if (IS_BROWSER || !since) {
    return;
  }
  const diff = process.hrtime(since);
  const elapsed = diff[0] * 1e9 + diff[1];
  const elapsedAsSeconds = elapsed / 1e9;
  return Number(elapsedAsSeconds.toFixed(4));
}
var Logger = class {
  isVerbose;
  isSilent;
  showDatetime;
  start;
  last;
  constructor({ silent = false, verbose = false, showDatetime = false } = {}) {
    this.isSilent = silent;
    this.isVerbose = verbose;
    this.showDatetime = showDatetime;
    this.start = timerStart();
  }
  debug(message, { category, metadata, elapsed } = {}) {
    if (LOG_LEVEL === "info" || LOG_LEVEL === "warn" || LOG_LEVEL === "error") {
      return;
    }
    if (!this.isVerbose || this.isSilent) {
      return;
    }
    console.info(
      ...this.assembleMessage("debug", message, { category, metadata, elapsed })
    );
    this.last = timerStart();
  }
  info(message, { category, metadata, elapsed } = {}) {
    if (LOG_LEVEL === "warn" || LOG_LEVEL === "error") {
      return;
    }
    if (this.isSilent) {
      return;
    }
    console.info(
      ...this.assembleMessage("info", message, { category, metadata, elapsed })
    );
    this.last = timerStart();
  }
  warn(message, { category, metadata, elapsed } = {}) {
    if (LOG_LEVEL === "error") {
      return;
    }
    if (this.isSilent) {
      return;
    }
    console.warn(
      ...this.assembleMessage("warn", message, { category, metadata, elapsed })
    );
    this.last = timerStart();
  }
  error(message, { exception, metadata, category, elapsed } = {}) {
    this.last = timerStart();
    if (this.isSilent) {
      return;
    }
    console.error(
      ...this.assembleMessage("error", message, {
        exception,
        category,
        metadata,
        elapsed
      })
    );
    this.last = timerStart();
  }
  groupStart(label) {
    if (this.isSilent) {
      return;
    }
    console.group(label);
    this.last = timerStart();
  }
  groupEnd(message, { category, metadata, elapsed } = {}) {
    if (this.isSilent) {
      return;
    }
    console.groupEnd();
    const data = this.assembleMessage(null, message, { category, metadata, elapsed });
    if (data.length > 0) {
      console.info(...data);
    }
    this.last = timerStart();
  }
  assembleMessage(level, message, { exception, category, metadata, elapsed } = {}) {
    const data = [];
    if (level) {
      data.push(`${level.toUpperCase()}`, "-");
    }
    if (this.showDatetime) {
      data.push((/* @__PURE__ */ new Date()).toISOString(), "-");
    }
    if (category) {
      data.push(`[${category}]`, "-");
    }
    if (message) {
      data.push(message, "-");
    }
    if (metadata) {
      data.push(metadata);
    }
    if (exception) {
      const exceptionAsPotentialHttpException = exception;
      if (exceptionAsPotentialHttpException.response) {
        delete exceptionAsPotentialHttpException.response;
      }
      if (exceptionAsPotentialHttpException.request) {
        delete exceptionAsPotentialHttpException.request;
      }
      data.push("exception", "-", exception);
    }
    if (elapsed) {
      elapsed.last && this.last && data.push(`(since last: ${timerEnd(this.last)})`);
      elapsed.start && this.start && data.push(`(since start: ${timerEnd(this.start)})`);
    }
    if (data[data.length - 1] === "-") {
      data.pop();
    }
    return data;
  }
};
var logger = new Logger({ verbose: process.env.NODE_ENV === "development" });
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Logger,
  logger,
  timerEnd,
  timerStart
});
